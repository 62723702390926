import React from 'react';
import { toast } from 'react-toastify';
import { arrayOf, func, oneOfType, shape, string } from 'prop-types';
import { isNilOrEmpty } from '@poly/utils/src/general.js';
import { ALERTS } from '@poly/site-ui/src/constants/alerts.js';
import { ButtonWithLoading } from '@poly/site-ui/src/components/ButtonWithLoading.js';
import { PrintAndExportButtons } from '@poly/site-ui/src/components/PrintAndExportButtons.js';
import { openPrintWindowWithData } from '@poly/client-utils/src/print.js';
import { performExcelExport } from '@poly/client-utils/src/excel-export/export.js';

import { PrintLayout } from '../../../components/print-visible-table.js';
import { PropertiesTableComponent } from '../PropertiesTable.js';
import {
  getPropertiesExcelConfig,
  getPropertiesExportFileName,
} from './getPropertiesExcelConfig.js';
import { usePropertiesLazyQuery } from './usePropertiesLazyQuery.js';

function ExportButton({ exportFunction }) {
  return <ButtonWithLoading asyncAction={exportFunction} />;
}

ExportButton.propTypes = {
  exportFunction: func.isRequired,
};

export function PropertyPrintAndExportButtons({ activeFilter, sort }) {
  const runPropertiesQuery = usePropertiesLazyQuery({ activeFilter, sort });

  const handleExportXLS = async () => {
    const properties = await runPropertiesQuery();

    if (isNilOrEmpty(properties)) {
      toast.error(ALERTS.NoDataToDisplay);
      return null;
    }
    const config = getPropertiesExcelConfig(properties, activeFilter);
    return performExcelExport(config);
  };

  const handlePrintPDF =
    ({ title }) =>
    async () => {
      const properties = await runPropertiesQuery();

      if (isNilOrEmpty(properties)) {
        toast.error(ALERTS.NoDataToDisplay);
        return null;
      }

      return openPrintWindowWithData({
        properties,
        fileName: getPropertiesExportFileName(activeFilter, 'pdf'),
        Table: PropertiesTableComponent,
        metaData: { title },
        Layout: PrintLayout,
        skipFirefoxStyles: true,
      });
    };

  return (
    <PrintAndExportButtons
      printTitle="Properties"
      exportFunction={handleExportXLS}
      printFunction={handlePrintPDF}
      ExportButtonComponent={ExportButton}
    />
  );
}

PropertyPrintAndExportButtons.propTypes = {
  activeFilter: string,
  sort: arrayOf(oneOfType([string, shape({})])),
};
