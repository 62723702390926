import React from 'react';
import * as R from 'ramda';
import { string, shape, arrayOf, oneOfType, func } from 'prop-types';
import { AbsoluteLoader, Paginator, Table } from '@poly/site-ui';
import { forceTitleCase } from '@poly/utils';
import {
  useHighlightMatchesBySearch,
  keywordSortQuery,
  commonSortQuery,
} from '@poly/client-utils';

import { PropertyLink } from '../../components/Links.js';
import { HighlightedCell } from '../ProjectsPage/ProjectsTable.js';
import { useClearTableSearch } from '../../hooks/useClearTableSearch.js';

import { usePropertiesQuery } from './usePropertiesQuery.js';

function MasterPropertyLink({ masterProperty }) {
  return masterProperty ? <PropertyLink {...masterProperty} /> : null;
}

MasterPropertyLink.propTypes = {
  masterProperty: shape({ _id: string.isRequired, name: string.isRequired }),
};

export const propertiesTableConfig = [
  ['Property', R.identity, PropertyLink, keywordSortQuery(['name'])],
  [
    'Address',
    R.path(['address', 'formatted_address']),
    HighlightedCell,
    keywordSortQuery(['address', 'formatted_address']),
  ],
  [
    'Master Property',
    R.identity,
    MasterPropertyLink,
    commonSortQuery(['masterProperty', 'name']),
  ],
  ['Status', R.prop('status'), forceTitleCase, commonSortQuery(['status'])],
];

const useTableProps = (properties) => ({
  rows: properties,
  columns: R.map(R.nth(1), propertiesTableConfig),
  headers: R.map(R.nth(0), propertiesTableConfig),
  formats: R.map(
    R.compose(R.defaultTo(R.identity), R.nth(2)),
    propertiesTableConfig,
  ),
  valuesToSort: R.map(R.nth(3), propertiesTableConfig),
  gridColumns: `
      minmax(100px, 300px)
      minmax(300px, 1fr)
      minmax(100px, 300px)
      90px
    `,
});

export function PropertiesTableComponent({ properties, tableSortingProps }) {
  const { rows, ...tableProps } = useTableProps(properties);

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    ['name', ['address', 'formatted_address']],
    rows,
    true,
  );
  return (
    <Table {...tableProps} rows={highlightedRows} {...tableSortingProps} />
  );
}

PropertiesTableComponent.propTypes = {
  properties: arrayOf(
    shape({
      _id: string.isRequired,
      name: string.isRequired,
      status: string.isRequired,
    }),
  ),
  tableSortingProps: shape({ onHeaderCellClick: func, sorting: shape({}) }),
};

export function PropertiesTable({ status, sort, ...tableSortingProps }) {
  useClearTableSearch();

  const { properties, total, loading } = usePropertiesQuery(status, sort);

  if (loading) {
    return <AbsoluteLoader />;
  }

  return (
    <>
      <PropertiesTableComponent
        properties={properties}
        tableSortingProps={tableSortingProps}
      />
      <Paginator total={total} />
    </>
  );
}

PropertiesTable.propTypes = {
  status: string.isRequired,
  sort: arrayOf(oneOfType([string, shape({})])),
};
