import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { useLazyQuery } from '@apollo/client';

import { useCurrentClientId } from '../../../hooks/useCurrentClientId.js';
import {
  getPropertiesQueryByStatusMap,
  propertiesQuery,
} from '../usePropertiesQuery.js';

export const usePropertiesLazyQuery = ({ activeFilter, sort }) => {
  const searchText = useSelector(R.prop('searchText'));

  const clientId = useCurrentClientId();

  const input = useMemo(
    () => ({
      searchTerm: searchText,
      query: getPropertiesQueryByStatusMap(activeFilter, clientId),
      size: 10000,
      sort,
    }),
    [searchText, activeFilter, clientId, sort],
  );

  const [runPropertiesQuery] = useLazyQuery(propertiesQuery);

  const getPropertiesQuery = async () => {
    const { data } = await runPropertiesQuery({ variables: { input } });

    return R.pathOr([], ['searchProperties', 'hits'], data);
  };

  return getPropertiesQuery;
};
