import React, { useState } from 'react';
import { propertyStatuses, ASC_SORT_ORDER } from '@poly/constants';
import { Layout } from '@poly/site-book';

import { Navigation } from '../Navigation.js';
import { LayoutContent } from '../MainLayout.js';
import { PropertiesTable, propertiesTableConfig } from './PropertiesTable.js';
import { PropertiesPageHeader } from './PropertiesPageHeader.js';
import { useTableSorting } from '../../hooks/useTableSorting.js';

export function PropertiesPage() {
  const [status, setStatus] = useState(propertyStatuses.ACTIVE);

  const { sort, ...tableSortingProps } = useTableSorting(
    1,
    ASC_SORT_ORDER,
    propertiesTableConfig,
  );

  return (
    <>
      <Layout.Header>
        <Navigation />
        <PropertiesPageHeader
          activeFilter={status}
          sort={sort}
          onClick={setStatus}
        />
      </Layout.Header>
      <LayoutContent>
        <PropertiesTable status={status} sort={sort} {...tableSortingProps} />
      </LayoutContent>
    </>
  );
}
