import {
  bool,
  func,
  array,
  number,
  object,
  string,
  oneOfType,
} from 'prop-types';
import React from 'react';
import * as R from 'ramda';
import Select from 'react-select';
import styled from 'styled-components';

import { usePropertiesSelectLogic } from '../../hooks/usePropertiesSelectLogic.js';

export const SpendReportSelectWrapper = styled.div`
  width: ${R.propOr('195px', 'width')};
  margin-left: 15px;
  position: relative;
`;

const SelectS = styled(Select)`
  height: 39px;
  width: 100%;

  .select__control {
    height: 40px;
    padding-bottom: 10px;
    border-color: #e3e5ed;
  }

  .select__control--is-focused {
    position: absolute;
    top: 0;
    width: 100%;
    height: 39px;
    border: none;

    input {
      line-height: 18px;
    }
  }

  .select__control:not(.select__control--is-focused) {
    .select__multi-value {
      background-color: #fff;
    }

    .select__multi-value__remove {
      display: none;
    }
  }

  .select__indicators {
    height: 39px;
  }

  .select__value-container {
    overflow: hidden;
    height: 100%;
  }

  .select__multi-value__label {
    font-size: 100%;
  }
`;

const Count = styled.div`
  width: 22px;
  height: 20px;
  margin-right: 10px;
  background-color: hsl(0, 0%, 90%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function DropdownIndicator({ hasValue, isFocused, getValue }) {
  const selectedProperties = getValue();
  const selectedPropertiesCount = selectedProperties.length;
  return hasValue && !isFocused && selectedPropertiesCount > 1 ? (
    <Count>{selectedPropertiesCount}</Count>
  ) : null;
}

DropdownIndicator.propTypes = {
  hasValue: bool.isRequired,
  isFocused: bool.isRequired,
  getValue: func.isRequired,
};

export const BaseSelectComponent = React.forwardRef((props, ref) => (
  <SelectS
    styles={{ menuPortal: (base) => ({ ...base, zIndex: 10 }) }}
    menuPortalTarget={document.body}
    menuPosition="absolute"
    classNamePrefix="select"
    ref={ref}
    closeMenuOnSelect={false}
    components={{
      IndicatorSeparator: () => null,
      DropdownIndicator,
    }}
    {...props}
  />
));

export function PropertySelect({ value, onChange, width }) {
  const selectRef = React.useRef(null);

  const { loading, options, onChangeHandler, onInputChange } =
    usePropertiesSelectLogic(onChange, true);

  return (
    <SpendReportSelectWrapper width={width}>
      <BaseSelectComponent
        placeholder="Start typing properties"
        noResultsText="No Property Found"
        onInputChange={onInputChange}
        onSelectResetsInput={false}
        onChange={onChangeHandler}
        isLoading={loading}
        options={options}
        ref={selectRef}
        value={value}
        isMulti
      />
    </SpendReportSelectWrapper>
  );
}

const optionPropTypes = oneOfType([array, object, string, number]);
PropertySelect.propTypes = {
  value: optionPropTypes,
  onChange: func,
  width: string,
};
