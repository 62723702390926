import React from 'react';
import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { commonSortQuery, keywordSortQuery } from '@poly/client-utils';
import { useTableSortingWrapper } from '@poly/site-ui';
import { formatDate } from '@poly/utils';
import {
  ELASTIC_RESULT_WINDOW_MAX_SIZE,
  NOTHING_UI_STRING,
  ASC_SORT_ORDER,
} from '@poly/constants';

import { ProjectLink } from '../../components/ProjectLink.js';

const CONFIRM_SATISFACTION_REPORT_QUERY = gql`
  query CONFIRM_SATISFACTION_REPORT_QUERY($input: CollectionSearchParams!) {
    searchProjects(input: $input) {
      hits {
        _id
        projectId
        description
        workCompletionDate
        contact {
          _id
          profile {
            fullName
          }
        }
      }
    }
  }
`;

// prepareQueryByFilters :: ID -> Filters -> Query
const prepareQueryByFilters = (clientId) =>
  R.compose(
    R.assocPath(['bool', 'must'], R.__, {}),
    R.reject(R.isNil),
    R.juxt([
      R.always({ match: { hasUncompletedConfirmSatisfactionTask: true } }),
      R.always({ exists: { field: 'workCompletionDate' } }),
      R.always({ term: { clientId } }),
      R.ifElse(
        R.propSatisfies(R.isNil, 'selectedProject'),
        R.always(null),
        R.applySpec({ term: { _id: R.path(['selectedProject', 'value']) } }),
      ),
      R.ifElse(
        R.propSatisfies(R.isNil, 'selectedRequestor'),
        R.always(null),
        R.applySpec({
          term: { contactId: R.path(['selectedRequestor', 'value']) },
        }),
      ),
      R.ifElse(
        R.both(
          R.propSatisfies(R.isNil, 'startDate'),
          R.propSatisfies(R.isNil, 'endDate'),
        ),
        R.always(null),
        ({ startDate, endDate }) => ({
          range: {
            workCompletionDate: {
              ...(!startDate ? {} : { gte: startDate }),
              ...(!endDate ? {} : { lte: endDate }),
              format: 'strict_date_optional_time',
            },
          },
        }),
      ),
    ]),
  );

export const confirmSatisfactionReportTableConfig = [
  [
    'Project #',
    ({ projectId }) => <ProjectLink projectId={projectId} target="_blank" />,
    R.prop('projectId'),
    keywordSortQuery(['projectId']),
  ],
  [
    'Project description',
    R.propOr(NOTHING_UI_STRING, 'description'),
    R.prop('description'),
    keywordSortQuery(['description']),
  ],
  [
    'Requestor',
    R.pathOr(NOTHING_UI_STRING, ['contact', 'profile', 'fullName']),
    R.path(['contact', 'profile', 'fullName']),
    keywordSortQuery(['contact', 'profile', 'fullName']),
  ],
  [
    'Completion Date',
    R.compose(formatDate, R.prop('workCompletionDate')),
    R.prop('workCompletionDate'),
    commonSortQuery(['workCompletionDate']),
  ],
];

// getQueryProjects :: { searchProjects: { hits: [Project] } } -> [Project]
const getQueryProjects = R.pathOr([], ['searchProjects', 'hits']);

export const useConfirmSatisfactionReportQuery = (filters, clientId) => {
  const { sort, ...tableProps } = useTableSortingWrapper({
    defaultColumn: 1,
    valuesToSortKey: 2,
    sortQueryOptionOrder: 3,
    defaultOrder: ASC_SORT_ORDER,
    tableConfig: confirmSatisfactionReportTableConfig,
  });

  const query = prepareQueryByFilters(clientId)(filters || {});

  const { data, loading } = useQuery(CONFIRM_SATISFACTION_REPORT_QUERY, {
    variables: { input: { query, sort, size: ELASTIC_RESULT_WINDOW_MAX_SIZE } },
    fetchPolicy: 'network-only',
    skip: !filters,
  });

  const projects = getQueryProjects(data);

  return { projects, loading, tableProps };
};
