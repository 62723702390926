import styled from 'styled-components';
import React, { useState, useMemo } from 'react';
import { Layout } from '@poly/site-book';

import {
  ProjectsPageHeader,
  preparePropertiesIds,
} from './ProjectsPageHeader.js';
import { Navigation } from '../Navigation.js';
import { PROJECTS_FILTERS } from './helpers.js';
import { SpendTotalInfo } from './SpendTotal.js';
import { LayoutContent } from '../MainLayout.js';
import { ProjectsTable } from './ProjectsTable.js';
import { useCurrentClientSpendTotalQuery } from './useClientSpendTotalQuery.js';
import { useProjectsQuery } from './useProjectsQuery.js';

const PageBodyContainer = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
`;

export function MainPage() {
  const [propertiesIds, setPropertiesIds] = useState([]);
  const [status, setStatus] = useState(PROJECTS_FILTERS.reactive);

  const preparedPropertiesIds = useMemo(
    () => preparePropertiesIds(propertiesIds),
    [propertiesIds],
  );

  useProjectsQuery(
    PROJECTS_FILTERS.reactive,
    preparedPropertiesIds,
    'network-only',
  );
  useProjectsQuery(
    PROJECTS_FILTERS.recurring,
    preparedPropertiesIds,
    'network-only',
  );
  useProjectsQuery(PROJECTS_FILTERS.pm, preparedPropertiesIds, 'network-only');
  useProjectsQuery(PROJECTS_FILTERS.all, preparedPropertiesIds, 'network-only');
  useProjectsQuery(
    PROJECTS_FILTERS.onHold,
    preparedPropertiesIds,
    'network-only',
  );
  useProjectsQuery(
    PROJECTS_FILTERS.pastDue,
    preparedPropertiesIds,
    'network-only',
  );
  useProjectsQuery(
    PROJECTS_FILTERS.completedPendingInvoice,
    preparedPropertiesIds,
    'network-only',
  );
  useProjectsQuery(
    PROJECTS_FILTERS.confirmSatisfaction,
    preparedPropertiesIds,
    'network-only',
  );

  const {
    lastMonthSpend,
    lastQuarterSpend,
    yearToDateSpend,
    completedProjectsLast30Days,
  } = useCurrentClientSpendTotalQuery();

  return (
    <>
      <Layout.Header>
        <Navigation />
      </Layout.Header>
      <PageBodyContainer>
        <SpendTotalInfo
          lastMonthSpend={lastMonthSpend}
          lastQuarterSpend={lastQuarterSpend}
          yearToDateSpend={yearToDateSpend}
          completedProjectsLast30Days={completedProjectsLast30Days}
        />
        <ProjectsPageHeader
          onClick={setStatus}
          activeFilter={status}
          propertiesIds={propertiesIds}
          setPropertiesIds={setPropertiesIds}
        />
        <LayoutContent>
          <ProjectsTable
            status={status}
            propertiesIds={preparePropertiesIds(propertiesIds)}
          />
        </LayoutContent>
      </PageBodyContainer>
    </>
  );
}
