import * as R from 'ramda';
import { ProjectSpendTypes } from '@poly/constants';
import {
  getPropertiesDeepByHierarchyNodeR,
  ofArrayLegacy,
  isNilOrEmpty,
  propEqLegacy,
} from '@poly/utils';

import { rejectAllOption } from '../../hooks/usePropertiesSelectLogic.js';

// isEmptyHierarchyNodeBranches :: [PropertyHierarchySpendReportResult] -> Boolean
export const isEmptyHierarchyNodeBranches = R.compose(
  R.equals(0),
  R.length,
  R.flatten,
  R.map(getPropertiesDeepByHierarchyNodeR([])),
);

// getOptionsValues :: Option -> String
export const getOptionsValues = R.map(R.prop('value'));

// toMutationInput :: Filters -> ClientSpendReportInput
export const toMutationInput = R.compose(
  R.over(
    R.lensProp('spendType'),
    R.ifElse(
      isNilOrEmpty,
      R.always(null),
      R.compose(
        R.when(
          propEqLegacy(0, ProjectSpendTypes.RECURRING_SERVICE),
          R.append(ProjectSpendTypes.PREVENTIVE_REPAIR),
        ),
        ofArrayLegacy,
        R.prop('value'),
      ),
    ),
  ),
  R.when(R.propSatisfies(isNilOrEmpty, 'propertyId'), R.dissoc('propertyId')),
  R.evolve({
    propertyId: R.compose(getOptionsValues, rejectAllOption),
  }),
);
