import * as R from 'ramda';
import { createExcelExportCell } from '@poly/utils/src/excel-export/helpers.js';
import { ExcelExportCellType } from '@poly/utils/src/excel-export/constants.js';
import { forceTitleCase } from '@poly/utils/src/strings.js';

export const getPropertiesExportFileName = (tab, ext) =>
  `properties_${tab}_export.${ext}`;

// getPropertyRowExcelConfig :: Property -> [ExcelExportDataCell]
const getPropertyRowExcelConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 4),
  ]),
  R.juxt([
    R.propOr('', 'name'),
    R.pathOr('', ['address', 'formatted_address']),
    R.pathOr('', ['masterProperty', 'name']),
    R.compose(forceTitleCase, R.prop('status')),
  ]),
);

// getPropertiesExcelConfig :: ([Property], String) -> ExcelConfig
export const getPropertiesExcelConfig = (properties, tab) => ({
  exportFileName: getPropertiesExportFileName(tab, 'xlsx'),
  columnWidths: [25, 45, 25, 15],
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 4)],
      ['Property', 'Address', 'Master property', 'Status'],
    ),
    ...R.map(getPropertyRowExcelConfig, properties),
  ],
});
