import React from 'react';
import * as R from 'ramda';
import { arrayOf } from 'prop-types';
import styled from 'styled-components';
import { isNilOrEmpty } from '@poly/utils';
import { Table } from '@poly/site-ui';

import { projectPropTypes } from './prop-types.js';
import { TextCenter } from '../SpendReportPage/components.js';
import { confirmSatisfactionReportTableConfig } from './useConfirmSatisfactionReportQuery.js';

const ConfirmSatisfactionReportWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    flex-direction: column;
  }
`;

const useTableProps = (projects) => ({
  rows: projects,
  columns: R.map(R.nth(1), confirmSatisfactionReportTableConfig),
  headers: R.map(R.nth(0), confirmSatisfactionReportTableConfig),
  formats: R.map(R.always(R.identity), confirmSatisfactionReportTableConfig),
  gridColumns: `
      110px
      minmax(300px, 1fr)
      minmax(150px, 300px)
      minmax(80px, 160px)
    `,
});

export function ConfirmSatisfactionReportPageTable({
  projects,
  ...tableProps
}) {
  const tableConfigProps = useTableProps(projects);

  if (isNilOrEmpty(projects)) {
    return <TextCenter>No data to report</TextCenter>;
  }

  return (
    <ConfirmSatisfactionReportWrapper>
      <Table {...tableConfigProps} {...tableProps} />
    </ConfirmSatisfactionReportWrapper>
  );
}

ConfirmSatisfactionReportPageTable.propTypes = {
  projects: arrayOf(projectPropTypes),
};
