import * as R from 'ramda';
import { useState, useCallback } from 'react';
import { gql, useQuery } from '@apollo/client';
import { ASC_SORT_ORDER } from '@poly/constants';
import { debounce } from '@poly/utils';
import {
  entityToOptionByLabelPath,
  keywordSortQuery,
} from '@poly/client-utils';

import { useCurrentClientId } from './useCurrentClientId.js';

const MultiSelectActions = { ADD: 'select-option', REMOVE: 'remove-value' };

const ALL_PROPERTIES = 'ALL_PROPERTIES';

export const ALL_SELECTED_OPTION = {
  value: ALL_PROPERTIES,
  label: 'All properties',
};

// isAllOption :: Option -> Boolean
export const isAllOption = R.propEq(ALL_PROPERTIES, 'value');

// rejectAllOption :: [Option] -> [Option]
export const rejectAllOption = R.reject(isAllOption);

// preparePropertyOptions :: [Property] -> [PropertySelectOption]
// PropertySelectOption = {
//    value: ID
//    label: String
//    isMaster: Boolean
//    masterProperty: Property
// }
const preparePropertyOptions = R.compose(
  R.map(R.mergeAll),
  R.map(
    R.juxt([
      entityToOptionByLabelPath(['name']),
      R.pick(['isMaster', 'masterProperty', 'childProperties']),
    ]),
  ),
);

const propertySelectOptionsFragment = gql`
  fragment propertySelectOptionsFragment on Property {
    _id
    name
    # "isMaster" and "masterProperty" fields used to
    # auto remove all child properties from property select filter
    # when master is removed
    isMaster
    masterProperty {
      _id
    }
  }
`;

const PROPERTIES_SEARCH_QUERY = gql`
  query PROPERTIES_SEARCH_QUERY($searchInput: CollectionSearchParams!) {
    searchProperties(input: $searchInput) {
      hits {
        ...propertySelectOptionsFragment
        # "childProperties" field used to auto add all
        # child properties to property select filter
        childProperties {
          ...propertySelectOptionsFragment
        }
      }
    }
  }

  ${propertySelectOptionsFragment}
`;

// getPropertiesOptions :: Boolean -> SearchProperties -> [Option]
const getPropertiesOptions = (includeAllOptions) =>
  R.compose(
    R.when(() => !!includeAllOptions, R.prepend(ALL_SELECTED_OPTION)),
    preparePropertyOptions,
    R.pathOr([], ['searchProperties', 'hits']),
  );

// removeAllChildPropertyOptions :: ID -> [PropertySelectOption] -> [PropertySelectOption]
const removeAllChildPropertyOptions = R.curry((masterPropertyId, options) =>
  R.reject(R.pathEq(masterPropertyId, ['masterProperty', '_id']))(options),
);

// addAllChildPropertyOptions :: Input -> [PropertySelectOption]
// Input = {
//    options: [PropertySelectOption]
//    childProperties: [Property]
// }
const addAllChildPropertyOptions = R.compose(
  rejectAllOption,
  R.uniqBy(R.prop('value')),
  R.converge(R.concat, [
    R.propOr([], 'options'),
    R.compose(preparePropertyOptions, R.propOr([], 'childProperties')),
  ]),
);

export const usePropertiesSelectLogic = (onChange, includeAllOptions) => {
  const [searchTerm, setSearchTerm] = useState('');

  const clientId = useCurrentClientId();

  const { data, loading } = useQuery(PROPERTIES_SEARCH_QUERY, {
    variables: {
      searchInput: {
        size: 25,
        searchTerm,
        query: { term: { clientId } },
        sort: keywordSortQuery(['name'])(ASC_SORT_ORDER),
      },
    },
    skip: !clientId,
  });

  const onChangeDebounced = useCallback(
    debounce(400)((searchText) => {
      setSearchTerm(searchText);
    }),
    [],
  );

  const onChangeHandler = (options, { action, option, removedValue }) => {
    const isAdd = action === MultiSelectActions.ADD;
    const isRemove = action === MultiSelectActions.REMOVE;

    if (isAllOption(option)) {
      return onChange([ALL_SELECTED_OPTION]);
    }

    if (isRemove && removedValue?.isMaster) {
      const newOptions = removeAllChildPropertyOptions(
        removedValue.value,
        options,
      );

      return onChange(newOptions);
    }

    if (isAdd && option.isMaster) {
      const newOptions = addAllChildPropertyOptions({
        options,
        childProperties: option?.childProperties,
      });

      return onChange(newOptions);
    }

    if (options) {
      return onChange(rejectAllOption(options));
    }

    return onChange([]);
  };

  const options = getPropertiesOptions(includeAllOptions)(data);

  return {
    loading,
    options,
    onChangeHandler,
    onInputChange: onChangeDebounced,
  };
};
