import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import PropTypes, { bool, func, string } from 'prop-types';
import { Header, HeadingH1, Holder, FilterByStatus, S } from '@poly/site-book';
import { useHasUserAccessWithPermission } from '@poly/client-utils';
import { READ_ASSET_PERMISSION } from '@poly/security';

import { TextSearchComponent } from '../../components/TextSearchComponent.js';
import { PrintAndExportButtons } from '../../components/print-visible-table.js';
import { MultiPropertiesFilter } from '../../components/MultiPropertiesFilter.js';
import { exportWorkOrdersDirectoryToXLS } from './export-to-xls.js';
import { useProjectsQuery } from './useProjectsQuery.js';
import { PROJECTS_FILTERS } from './helpers.js';

const HeaderBlockRight = styled(Header.Block)`
  align-self: flex-end;
`;

const UpRow = styled(Header.Row)`
  margin-top: -10px;
`;

const FilterByStatusS = styled(FilterByStatus)`
  background-color: transparent;
  border: transparent;
  border-bottom: 4px solid
    ${({ active }) => (active ? '#ff8c00' : 'transparent')};

  &:hover {
    padding-bottom: ${({ active }) => !active && '17px'};
  }
`;

const FILTERS_CONFIG = [
  {
    title: 'Reactive',
    status: PROJECTS_FILTERS.reactive,
  },
  {
    title: 'Recurring',
    status: PROJECTS_FILTERS.recurring,
  },
  {
    title: 'PM',
    status: PROJECTS_FILTERS.pm,
  },
  {
    title: 'All',
    status: PROJECTS_FILTERS.all,
  },
  {
    title: 'On Hold',
    status: PROJECTS_FILTERS.onHold,
  },
  {
    title: 'Past Due',
    status: PROJECTS_FILTERS.pastDue,
  },
  {
    title: 'Completed Pending Invoice',
    status: PROJECTS_FILTERS.completedPendingInvoice,
  },
  {
    title: 'Confirm Satisfaction',
    status: PROJECTS_FILTERS.confirmSatisfaction,
  },
];

// preparePropertiesIds :: [Option] -> [ID]
export const preparePropertiesIds = R.map(R.prop('value'));

function Filter({ status, title, active, onClick, propertiesIds }) {
  const { total } = useProjectsQuery(
    status,
    preparePropertiesIds(propertiesIds),
  );

  return (
    <FilterByStatusS active={active} onClick={() => onClick(status, total)}>
      <S type="title">
        {title} ({total})
      </S>
    </FilterByStatusS>
  );
}

const propertiesIdsPropType = PropTypes.arrayOf(
  PropTypes.shape({ value: PropTypes.string }),
).isRequired;

Filter.propTypes = {
  active: bool.isRequired,
  title: string.isRequired,
  onClick: func.isRequired,
  status: string.isRequired,
  propertiesIds: propertiesIdsPropType,
};

const FiltersContainer = styled(Header.Sub)`
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
`;

const FILTERS_WITHOUT_PMS = FILTERS_CONFIG.filter(
  (tab) => tab.status !== PROJECTS_FILTERS.pm,
);

export function ProjectsPageHeader({
  onClick,
  activeFilter,
  propertiesIds,
  setPropertiesIds,
}) {
  const isAuthorizedToReadAssets = useHasUserAccessWithPermission(
    READ_ASSET_PERMISSION,
  );
  const tabs = isAuthorizedToReadAssets ? FILTERS_CONFIG : FILTERS_WITHOUT_PMS;
  return (
    <FiltersContainer>
      <Header.Row>
        <Header.Block>
          <HeadingH1 lighter>Active Work Orders</HeadingH1>
        </Header.Block>
        <Header.Block>
          <PrintAndExportButtons
            printTitle="Work Orders"
            exportFunction={exportWorkOrdersDirectoryToXLS}
          />
        </Header.Block>
      </Header.Row>
      <UpRow>
        <Holder margin={0} center>
          {tabs.map(({ status, ...props }) => (
            <Filter
              key={status}
              status={status}
              onClick={onClick}
              propertiesIds={propertiesIds}
              active={activeFilter === status}
              {...props}
            />
          ))}
        </Holder>
        <HeaderBlockRight>
          <TextSearchComponent />
          <MultiPropertiesFilter
            value={propertiesIds}
            onChange={setPropertiesIds}
          />
        </HeaderBlockRight>
      </UpRow>
    </FiltersContainer>
  );
}

ProjectsPageHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
  propertiesIds: propertiesIdsPropType,
  activeFilter: PropTypes.string.isRequired,
  setPropertiesIds: PropTypes.func.isRequired,
};
