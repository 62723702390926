import * as R from 'ramda';
import { renameProp } from '@poly/utils';
import { useSortableTable } from '@poly/client-utils';
import { ASC_SORT_ORDER, DESC_SORT_ORDER } from '@poly/constants';

const getESOrder = (dir) => (dir === 1 ? ASC_SORT_ORDER : DESC_SORT_ORDER);

export const useTableSortingWrapper = ({
  tableConfig,
  defaultOrder,
  defaultColumn,
  valuesToSortKey,
  sortQueryOptionOrder,
  // array of column indexes - [0, 1, 2, ...]
  // use this to cancel trigger sorting on "Select All" checkbox
  columnsToSkipSorting = [],
}) => {
  const { sort, sorting, setSorting } = useSortableTable({
    column: defaultColumn,
    order: defaultOrder,
    tableConfig,
    sortQueryOptionOrder,
  });

  const modifiedSetSorting = ({ key, dir }) => {
    if (R.includes(key, columnsToSkipSorting)) {
      return;
    }

    const order = getESOrder(dir);
    const query = tableConfig[key][sortQueryOptionOrder](order);

    setSorting({ key, dir, query });
  };

  return {
    sort,
    sorting: renameProp('columnKey', 'key')(sorting),
    setSorting: modifiedSetSorting,
    valuesToSort: R.map(R.nth(valuesToSortKey), tableConfig),
  };
};
