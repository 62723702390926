import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon, SelectComponents } from '@poly/site-book';
import { SelectBase } from '@poly/site-ui';

import { usePropertiesSelectLogic } from '../hooks/usePropertiesSelectLogic.js';

const MultiSelectorBaseWrapperS = styled.div`
  display: flex;
  position: relative;
  margin-left: 20px;
`;

const MultiSelectorBaseS = styled(SelectBase)`
  z-index: 1;

  .Select__control {
    width: 200px;
    max-height: 40px;
  }

  .Select__control > .Select__value-container {
    padding: 7px 7px 7px 25px;
    max-height: 38px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .Select__indicators {
    align-items: baseline;
  }

  .Select__indicator.Select__clear-indicator {
    display: none;
  }

  .Select__placeholder {
    color: #babfd2;
  }

  .Select__indicator.Select__dropdown-indicator {
    padding: 8px 8px 8px 0;
  }

  .Select__multi-value {
    background-color: #f6f9fd;
  }

  .Select__option--is-selected {
    display: flex;
    background-color: #f6f9fd;
    color: inherit;
  }

  .Select__menu {
    width: 150%;
    right: 0;
  }
`;

const PrefixIconS = styled(Icon)`
  display: flex;
  position: absolute;
  left: 8px;
  top: 13px;
`;

const OptionTextS = styled.span`
  display: flex;
  width: 95%;
`;

function FilterIcon({ prefix }) {
  const IconComponent = prefix ? PrefixIconS : Icon;

  return (
    <IconComponent
      fill="#000"
      name="search-filter"
      dimensions={{ width: 12 }}
    />
  );
}

FilterIcon.propTypes = { prefix: PropTypes.bool };

function CustomControl({ children, ...props }) {
  return (
    <SelectComponents.Control {...props}>
      <FilterIcon prefix />
      {children}
    </SelectComponents.Control>
  );
}

CustomControl.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

function CustomOption({ children, ...props }) {
  const { isSelected } = props;
  return (
    <SelectComponents.Option {...props}>
      <OptionTextS>{children}</OptionTextS>
      {isSelected && <FilterIcon />}
    </SelectComponents.Option>
  );
}

CustomOption.propTypes = {
  children: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export function MultiPropertiesFilter({ onChange, ...props }) {
  const { loading, options, onChangeHandler, onInputChange } =
    usePropertiesSelectLogic(onChange);

  return (
    <MultiSelectorBaseWrapperS>
      <MultiSelectorBaseS
        {...props}
        isMulti
        options={options}
        isLoading={loading}
        closeMenuOnSelect={false}
        onChange={onChangeHandler}
        hideSelectedOptions={false}
        onSelectResetsInput={false}
        onInputChange={onInputChange}
        placeholder="Filter by property"
        noResultsText="No property found"
        components={{ Control: CustomControl, Option: CustomOption }}
      />
    </MultiSelectorBaseWrapperS>
  );
}

MultiPropertiesFilter.propTypes = { onChange: PropTypes.func.isRequired };
