import React from 'react';
import PropTypes from 'prop-types';

import { iconsConfig } from './iconsConfig.js';

function Icon({ name, fill, dimensions, className }) {
  const IconC = iconsConfig[name];

  if (!IconC) {
    return null;
  }

  return <IconC fill={fill} dimensions={{ ...dimensions, className }} />;
}

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  fill: PropTypes.string,
  dimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};

Icon.defaultProps = {
  fill: '#3a3d46',
};

Icon.displayName = 'Icon';

export default Icon;
